import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  api_url = environment.api_url;
  client_api_url = environment.client_api_url;
  constructor(
    private http: HttpClient
  ) { }

  // service to register user
  checkMobile(data): Observable<any[]> {
    const param = {
      'mobile' : '91' + data,
      'userRole' : 'USER',
      'isEmailVerified' : false,
      'isActive' : false
    };
    return this.http.post<any>(this.api_url + 'account/register', param);
  }

  // serivce to check Otp sent on mobile and create account
  checkOtp(number, otp): Observable<any[]> {
    const param = {
      'mobile' : '91' + number,
      'otp' : otp
    };
    return this.http.post<any>(this.api_url + 'account/otpVerification', param, httpOptions);
  }

  // resend or send otp, just send mobile no
  resendOTP(number): Observable<any[]> {
    const param = {
      'mobile' : '91' + number
    };
    return this.http.post<any>(this.api_url + 'account/sendOtp', param, httpOptions);
  }

  // register mobile no with pass
  register(obj): Observable<any[]> {
    const param = {
      'mobile': '91' + obj.number,
      'otp' : obj.otp,
      'password': obj.password
    };
    return this.http.post<any>(this.api_url + 'account/addPassword', param, httpOptions);
  }

  // activate  profile just send mobile no after successfully adding password
  activateProfile(number) {
    const param = {
      'mobile': '91' + number
    };
    console.log('register mobile number',param);
    return this.http.post<any>(this.api_url + 'account/activateProfile', param, httpOptions);
  }

  verifyEmail(type, hash): Observable<any> {
    return this.http.get(this.client_api_url + 'account/v2/validateEmailVerificationLink/' + type + "/" + hash, httpOptions)
  }
}
